import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
  width: 200,
  height: 300,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const ViewImage = ({ open, handleClose, pic }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="viewImageInScale">
        <div className="viewImageChild1" >
        <span style={{color:"white"}}>{pic?.name}</span>
          <span onClick={handleClose} style={{cursor:"pointer"}}>
           
            <RxCross2 color="white" fontSize={35} />
          </span>
        </div>
        <Box sx={style}>
          <div
            className=""
            style={{
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "12px",
            }}
          >
            <img
              src={`data:image/png;base64,${pic?.pic}`}
              alt="ID Proof"
              style={{
                width: "500px",
                height: "500px",
                objectFit: "contain",
                borderRadius: "12px",
              }}
            />
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default ViewImage;
