import React, { useContext, useEffect, useState } from 'react'
import {notifications} from "./myProvider"
import {
  initiateSocketConnection,  
  disconnectSocket,
  subscribeToEvent,
  emitEvent,
} from '../services/socketServices';

export const useNotifications=()=>{
    return useContext(notifications);
}
const NotificationsProvider = ({children,token}) => {
    const [notification,setNotification]=useState([]);
    const [socket, setSocket] = useState(null);
    const [unreadLength, setUnreadLength] = useState(null);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);
    const [notificationList,setNotificationList]=useState([]);
    





    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (token) {
            initiateSocketConnection(token);
            setConnected(true);

            return () => {
                disconnectSocket();
                setConnected(false);
            };
        }
    }, [token]);

    const emit = (eventName, data) => {
        emitEvent(eventName, data);
    };

    const subscribe = (eventName, callback) => {
        subscribeToEvent(eventName, callback);
    };
   const socketDisconnected=()=>{
    disconnectSocket();
    setConnected(false);
   };
 const socketConnect=(YourToken)=>{
    if(YourToken){
        initiateSocketConnection(YourToken);
        setConnected(true);
    }
 }
  return (
    <notifications.Provider
    value={{
        notification,
        setNotification,
        socket,
        setSocket,
        unreadLength,
        setUnreadLength,
        loading, setLoading,
        error, setError,
        connected,
        emit,
        subscribe,
        socketDisconnected,
        socketConnect,
        notificationList,
        setNotificationList
    }}
    
    >{children}</notifications.Provider>
  )
}

export default NotificationsProvider