import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { useCustomer } from "../../context/CustomerProvider";
import { useEmployee } from "../../context/EmpProvider";
import axios from "axios";
import { useRightSidebar } from "../../hooks/useRightSidebar/useRightSidebar";
import { useNotifications } from "../../context/NotificationsProvider";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: 0,
  outline: 0,
};
const Confirm = ({
  open,
  handleClose,
  message,
  setRightSidebar = () => {},
  info = null,
  employeeDetails = null,
  filteredEmployees = null,
  setFilteredEmployees = () => {},
  commonList = null,
  employeeSelectedForRetrend = null,
  setDisplayedEmployees = () => {},
  displayedEmployees = null,
  searchFilter = false,
  setEmployeeSelectedForRetrend=()=>{},
  setIsSelected=()=>{},
  setselectedLength=()=>{},
  loadMoreEmployees=()=>{},
  
}) => {
  const navigate = useNavigate();
  const { customer, setCustomer } = useCustomer();
  const { setNotification, setUnreadLength, setNotificationList, setError,socketDisconnected } =
    useNotifications();

  // console.log(customer?.data?.customerid);
  const { emit } = useNotifications();
  const {
    handleTerminated,
    handleSuspended,
    setEmployee,
    employee,
    setEmployeeDetails,
    setCommonRightSidebar,
  } = useEmployee();

  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
    localStorage.removeItem("token");
    socketDisconnected();
    setCustomer({
      isLogin: false,
      token: "",
      customerId: "",
      data: null,
    });
    setEmployeeDetails({
      _id: null,
      customerid: "",
      firstName: "",
      isFaceRegistered: false,
      lastName: "",
      employeeId: "",
      dob: "",
      gender: "",
      emailPersonal: "",
      emailOfficial: "",
      mobileNo: "",
      address: "",
      joiningDate: "",
      department: "",
      designation: "",
      employmentType: "",
      workLocation: "",
      blockOrBuildingNo: "",
      shift: "",
      idProofType: "",
      idProofNo: "",
      idProofPhoto: null,
      isTerminated: false,
      isSuspended: false,
      createdAt: "",
      updatedAt: "",
      profilePhoto: "",
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      isHr: false,

      __v: null,
    });
    setEmployee([]);
    setNotificationList([]);
    setUnreadLength(null);
    setNotification([]);
    setError(null);
    navigate("/login");
  };

  const deleteRecords = async () => {
    try {
      setLoading(true);
      const { data } = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/delete-record`,
        {
          data: { customerID: "OKFS2" },
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      if (data?.success) {
        setLoading(false);

        handleClose();
      }
      // setUserData(response.data);
    } catch (error) {
      setLoading(false);

      handleClose();
      console.error("Error fetching user data:", error);
    }
  };
  const nominateHr = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/employees/update-hr`,
        {
          employeeId: info?.id,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response?.data?.success) {
        setLoading(false);
        handleClose();

        const allHrFalse = employee?.map((e) => {
          return { ...e, isHr: false };
        });

        const info = allHrFalse?.map((e) => {
          if (e?._id === response?.data?.data?._id) {
            if (response?.data?.data) {
              setEmployeeDetails(response?.data?.data);
            }

            return response?.data?.data;
          }
          return e;
        });
        
        const filterEmployees = filteredEmployees || [];

        const updatedEmployees = filterEmployees?.map((employee) => {
          if (employee._id === response?.data?.data?._id) {
            if (response?.data?.data) {
              setEmployeeDetails(response?.data?.data);
            }

            return {
              ...employee,
              isHr: true,
            };
          }

          return employee;
        });

        setFilteredEmployees(updatedEmployees);
        setEmployee(info);
        setCustomer((prev) => ({
          ...prev,
          data: {
            ...prev.data,
            isHr: {
              employeeId: response?.data?.data?.employeeId,
              isHrAssign: true,
            },
          },
        }));

        setRightSidebar(false);
        // console.log(response?.data?.firstName,response?.data)
        const infoData = {
          type: "hrNominated",
          customerId: customer?.data?._id,
          payload: {
            message: `${response?.data?.data?.firstName} ${response?.data?.data?.lastName} is nominated as an HR.`,
            details: {},
          },
        };
        emit("reactEvent", infoData);
      }
    } catch (error) {
      handleClose();
      setLoading(false);
      setRightSidebar(false);
    }
  };

  // const handleReCapture = async () => {
  //   try {
  //     const listOfReTrend = employeeSelectedForRetrend?.map((ir) => {
  //       return {
  //         employeeID: ir?._id,
  //         isReTrain: ir?.isReTrain,
  //         firstName:ir?.firstName,
  //         lastName:ir?.lastName,
  //         vaId:ir?.vaId,
  //       };
  //     });

  //     const url = 'https://fsvaapi.facesense.in:7000/delete-images';
  //     const data = {
  //       client_id: `${customer?.data?.customerid}`,
  //       image_names: listOfReTrend?.map((emp)=>emp?.vaId)
  //     };

  //     try {
  //       const response = await axios.post(url, data);
  //       console.log('Response:', response.data);
  //     } catch (error) {
  //       console.error('Error deleting images:', error.response ? error.response.data : error.message);
  //     }
  //     setLoading(true);
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/employees/reTrend-employee`,
  //       {
  //         listOfReTrend,
  //       },
  //       {
  //         headers: {
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     );

  //     if (response?.data?.success) {
  //       setLoading(false);

  //       const infoData={
  //         type:"employeeRetrain",
  //         customerId:customer?.data?._id,
  //         payload:{
  //           message:`${listOfReTrend?.length} employees have been selected for retraining, involving a repeat of the face capture process to ensure accurate recognition`,
  //           details:{
  //           list:listOfReTrend,
  //           }
  //         },
  //       }
  //       emit("reactEvent",infoData);

  //       const updatedEmployees = employee?.map((e) => {
  //         const updatedItem = listOfReTrend?.find((item) => item.employeeID === e._id);

  //         if (updatedItem) {
  //           const updatedData = { ...e, isReTrainStrict: updatedItem.isReTrainStrict,profilePhoto:null };

  //           if (updatedItem.isReTrainStrict) {
  //             updatedData.isReTrainStrict = true;
  //           }

  //           return updatedData;
  //         }

  //         return e;
  //       });

  //       setEmployee(updatedEmployees);
  //       setEmployeeDetails(updatedEmployees[0]);

  // if (!searchFilter) {
  //   const updatedFilteredEmployees = filteredEmployees?.map((e) => {
  //     const updatedItem = listOfReTrend?.find((item) => item.employeeID === e._id);

  //     if (updatedItem) {
  //       const updatedData = { ...e, isReTrainStrict: updatedItem.isReTrainStrict,profilePhoto:null };

  //       if (updatedItem.isReTrainStrict) {
  //         updatedData.isReTrainStrict = true;
  //       }

  //       return updatedData;
  //     }

  //     return e;
  //   });

  //   setFilteredEmployees(updatedFilteredEmployees);
  //   setEmployeeDetails(updatedFilteredEmployees[0]);
  // }
  // setDisplayedEmployees((prev) =>
  //   prev.map((employee) => ({
  //     ...employee,
  //     isRetrainFrontend: false,
  //   }))
  // );
  //       handleClose();
  //       setCommonRightSidebar(false);
  //     }
  //   } catch (e) {
  //     setDisplayedEmployees((prev) =>
  //       prev.map((employee) => ({
  //         ...employee,
  //         isRetrainFrontend: false,
  //       }))
  //     );
  //     setLoading(false);
  //     handleClose();
  //     setCommonRightSidebar(false);
  //     console.error(e);
  //   }
  // };

  const handleReCapture = async () => {
   
    try {
      const listOfReTrend = employeeSelectedForRetrend?.map((ir) => ({
        employeeID: ir?._id,
        isRetrainFrontend: ir?.isRetrainFrontend,
        firstName: ir?.firstName,
        lastName: ir?.lastName,
        vaId: ir?.vaId,
      }))
      
     .filter((isRetrainFrontend)=>isRetrainFrontend?.isRetrainFrontend);
     setLoading(true);
     console.log(listOfReTrend);
     await deleteImagesFromServer(customer?.data?.customerid, listOfReTrend);
     const response = await updateEmployeeRetrainStatus(listOfReTrend);
      
      if (response?.data?.success) {
          
        setLoading(false);
        emitRetrainEvent(listOfReTrend);
       
        const filterEmployees = filteredEmployees || [];
        const updatedFilteredEmployees = updateEmployeesInState(
        filterEmployees,
        listOfReTrend
      );
      const updatedEmployees = updateEmployeesInState(
        employee,
        listOfReTrend
      );
        setFilteredEmployees(updatedFilteredEmployees);
        setEmployee(updatedEmployees);
          setDisplayedEmployees((prev) =>
          prev.map((employee) => ({
            ...employee,
            isRetrainFrontend: false,
          }))
        );
        setEmployeeSelectedForRetrend([]);
        setIsSelected(false);
        setselectedLength(0)
        handleClose();
        setCommonRightSidebar(false);
      }
    } catch (error) {
      handleErrorDuringRetrain();
      setLoading(false);
      console.error(error);
    }
  };

  const deleteImagesFromServer = async (clientId, listOfReTrend) => {
    const url = "https://fsvaapi.facesense.in:7000/delete-images";
    const data = {
      client_id: clientId,
      image_names: listOfReTrend?.map((emp) => emp?.vaId),
    };
    console.log(data);
    await axios.post(url, data);
  };

  const updateEmployeeRetrainStatus = async (listOfReTrend) => {
    return await axios.put(
      `${process.env.REACT_APP_BASE_URL}/employees/reTrend-employee`,
      { listOfReTrend },
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
  };

  const emitRetrainEvent = (listOfReTrend) => {
    const infoData = {
      type: "employeeRetrain",
      customerId: customer?.data?._id,
      payload: {
        message: `${listOfReTrend?.length} employees have been selected for retraining, involving a repeat of the face capture process to ensure accurate recognition`,
        details: { list: listOfReTrend },
      },
    };
    emit("reactEvent", infoData);
  };

  const updateEmployeesInState = (employeeArray, listOfReTrend) =>
    employeeArray?.map((e) => {
      const updatedItem = listOfReTrend?.find(
        (item) => item.employeeID === e._id
      );
      if (updatedItem) {
        return {
          ...e,
          isReTrainStrict: updatedItem.isRetrainFrontend,
          profilePhoto: null,
        };
      }
      return e;
    });

  const handleErrorDuringRetrain = () => {
    setDisplayedEmployees((prev) =>
      prev.map((employee) => ({
        ...employee,
        isRetrainFrontend: false,
      }))
    );
    setEmployeeSelectedForRetrend([])
    setLoading(false);
    setIsSelected(false);
    setselectedLength(0)
    handleClose();
    setCommonRightSidebar(false);
  };

  const handleConfirm = async () => {
    // console.log(message?.type);
    if (message?.type === "Logout") {
      handleLogout();
    } else if (message?.type === "Warning") {
      deleteRecords();
    } else if (message?.type === "terminate") {
      try {
        const isSuccess = await handleTerminated(employeeDetails);
        if (isSuccess) {
          handleClose();
        }
      } catch (e) {
        handleClose();
        console.log(e);
      }
    } else if (message?.type === "terminate") {
      return;
    } else if (message?.type === "Suspend" || message?.type === "Suspended") {
      try {
        const type = message?.type;
        const isSuccess = await handleSuspended(employeeDetails, type);
        if (isSuccess) {
          handleClose();
        }
      } catch (e) {
        handleClose();
        handleClose();
      }
    } else if (message?.type === "WarningHr") {
      setRightSidebar(true);
      handleClose();
      return;
    } else if (message?.type === "WarningChangeHr") {
      if (info?.name) {
        nominateHr();
      }
      return;
    } else if (message?.type === "recapture") {
      handleClose();
      setCommonRightSidebar(true);
      return;
    } else if (message?.type === "WarningSendReCapture") {
      handleReCapture();
      return;
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="Popup_Box">
          <div className="Popup_Text">
            <h1>{message?.title}</h1>
          </div>
          <div className="contentAlter" style={{ textAlign: "center" }}>
            <p>{message?.content}</p>
          </div>

          <div className="Popup_Button">
            {message?.type === "WarningChangeHr" ? (
              info?.name && (
                <button className="loginBtn" onClick={handleConfirm}>
                  {loading ? "Loading.." : `${message?.leftBtn}`}
                </button>
              )
            ) : (
              <>
                {(message?.title === "No Employees Selected" && message?.type === "WarningSendReCapture")
                ? (
                  ""
                ) : (
                  <button className="loginBtn" onClick={handleConfirm}>
                    {loading ? "Loading.." : `${message?.leftBtn}`}
                  </button>
                )}
              </>
            )}

            <button
              className="cancelBtn"
              onClick={() => {
                handleClose();
              }}
            >
              {message?.rightBtn}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Confirm;
