import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import "./App.css";
import "./styles/colors/color.css";
import "./components/Layout/AdminLayout/layout.css";
import "./components/Layout/Navbar/navbar.css";
import "./components/Layout/Sidebar/sidebar.css";
import "./pages/reports/reportsDetails.css";
import "./pages/adminAccount/adminAcount.css";
import "./pages/liveTracker/liveTracker.css";
import "./pages/exployeeDetails/employeeDetails.css";
import "./pages/Dashboard/dashboard.css";
import "./pages/auth/login/login.css";
import "./model/logDetails/logDetails.css";
import "./model/confirm/confirm.css";
import "./model/editProfile/editProfile.css";
import Loader from "./assets/gif/Loader.gif";
import "./pages/animation/animation.css";
import "./skeleton/employeeDetails/employeeInfo.css";
import "./skeleton/reports/table.css";
import "./skeleton/liveTracker/liveTracker.css";
import "./model/nominate/nominateHr.css";
import "./components/employeeDetails/nominate.css";
import "./components/reports/reportTable/attendanceTable.css";
import "./pages/notifications/notifications.css"
import Dashboard from "./pages/Dashboard/Dashboard";
import ReportsDetails from "./pages/reports/ReportsDetails";
import AdminAccount from "./pages/adminAccount/AdminAccount";
import LiveTracker from "./pages/liveTracker/LiveTracker";
import EmployeeDetails from "./pages/exployeeDetails/EmployeeDetails";
import Login from "./pages/auth/login/Login";
import ChangePassword from "./pages/auth/changePassword/ChangePassword";
import NewPassword from "./pages/auth/newPassword/NewPassword";
import Forgetpassword from "./pages/auth/forgetPassword/Forgetpassword";
import Animation from "./pages/animation/Animation";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { useCustomer } from "./context/CustomerProvider";
import RedirectPage from "./pages/redirect/RedirectPage";
import { useEmployee } from "./context/EmpProvider";
import Notifications from "./pages/notifications/Notifications";
import { useNotifications } from "./context/NotificationsProvider";
// import { io } from 'socket.io-client';
import moment from "moment";

const PrivateRoute = () => {
  const { customer, isAuthenticated } = useCustomer();

  return customer && isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};
const PublicRoute = () => {
  
  const isAuthenticated = localStorage.getItem("token") || null;
    return  !isAuthenticated ? <Outlet /> : <Navigate to="/dashboard" replace/>
  }


const UnknownRoute = () => {
  const {socketDisconnected,setNotificationList,setNotification,setUnreadLength,setError } = useNotifications();
  localStorage.removeItem("token");
  socketDisconnected();
  const {setCustomer } = useCustomer();
  const {
    setEmployee,
    setEmployeeDetails,
  } = useEmployee();
  
  setCustomer({
    isLogin: false,
    token: "",
    customerId: "",
    data: null,
  });
  setEmployeeDetails({
    _id: null,
    customerid: "",
    firstName: "",
    isFaceRegistered: false,
    lastName: "",
    employeeId: "",
    dob: "",
    gender: "",
    emailPersonal: "",
    emailOfficial: "",
    mobileNo: "",
    address: "",
    joiningDate: "",
    department: "",
    designation: "",
    employmentType: "",
    workLocation: "",
    blockOrBuildingNo: "",
    shift: "",
    idProofType: "",
    idProofNo: "",
    idProofPhoto: null,
    isTerminated: false,
    isSuspended: false,
    createdAt: "",
    updatedAt: "",
    profilePhoto: "",
    addressLine1: "",
    addressLine2: "",
    pincode: "",
    city: "",
    isHr: false,

    __v: null,
  });
  setEmployee([]);
  setNotificationList([]);
  setUnreadLength(null);
  setNotification([]);
  setError(null);


  return <Navigate to="/login" replace />;
};

function App() {
  const { setCustomer, customer, setError, setIsAuthenticated, isUpdate } =
    useCustomer();
    
  const {
    department,
    setDepartment,
    employment,
    setEmployment,
    designation,
    setDesignation,
    workLocation,
    setWorkLocation,
    setErrorMultipleFilter,
    setLoadingMultipleFilter,
  } = useEmployee();
  const {setNotification,setUnreadLength,setLoading:setLoadingNotifications,setError:setErrorNotifications,
    connected,
    emit,
    subscribe,
    notificationList,
    setNotificationList,
  }=useNotifications();




  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const fetchUserData = async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/getuser`,

            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          // console.log(data, "data");
          setLoading(false);
          setCustomer({ ...customer, data: data });
          setIsAuthenticated(true);
        } catch (error) {
          setError("Failed to fetch user data.");
          setIsAuthenticated(false);
          setLoading(false);
        }
      };

      fetchUserData();
    } else {
      setError("No customerid found. Please log in.");
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, [isUpdate]);



 
  const groupNotificationsByDate = (notifications) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    
    // First, sort notifications by date in descending order
    const sortedNotifications = notifications.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
    
    // Group notifications by "Today", "Yesterday", and other dates
    const grouped = sortedNotifications.reduce((acc, notification) => {
      const notificationDate = moment(notification.createdAt);
      
      let dateLabel;
      if (notificationDate.isSame(today, 'day')) {
        dateLabel = "Today";
      } else if (notificationDate.isSame(yesterday, 'day')) {
        dateLabel = "Yesterday";
      } else {
        dateLabel = notificationDate.format("DD/MM/YYYY");
      }
      
      if (!acc[dateLabel]) {
        acc[dateLabel] = [];
      }
      acc[dateLabel].push(notification);
      
      return acc;
    }, {});
    
    // Sort the notifications within each date group (e.g., "Today", "Yesterday") in descending order by `createdAt`
    Object.keys(grouped).forEach((key) => {
      grouped[key].sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
    });
  
    return grouped;
  };

 


  
useEffect(() => {
  if (connected) {
      setError(null); 
      const unsubscribe = subscribe('notifyReact', (data) => {
          let newNotification = data?.payload || null; 
          const isNotificationPath = window.location.pathname === '/notifications';
          if(isNotificationPath){
            
            newNotification.readTypeFats=true
            setUnreadLength(null);
            const info={
              type:"readType",
              payload:data,
              customerId:customer?.customerId,
              strictType:"fats",
            }
            emit("readTypeChange",info);
          }
          setNotificationList((prevNotification) => {


            if (!prevNotification || prevNotification.length === 0) {
              // console.log(newNotification)
              const initialNotifications = newNotification ? [newNotification] : [];
              const groupedNotifications = groupNotificationsByDate(initialNotifications);
    
              if (!isNotificationPath && newNotification && !newNotification.readTypeFats) {
                setUnreadLength(initialNotifications.length);
              }
              // console.log("check",groupedNotifications)
    
              setNotification(groupedNotifications);
              return initialNotifications;
            }











              const existingNotifications = new Set(prevNotification?.map((notify) => notify._id));

              if (newNotification && !existingNotifications.has(newNotification._id)) {
                  const combinedNotifications = [...prevNotification, newNotification];
                  const groupedNotifications = groupNotificationsByDate(combinedNotifications);
                  // console.log(combinedNotifications)
                  const unreadNotifications = combinedNotifications.filter((un) => !un.readTypeFats);
if(!isNotificationPath){
  setUnreadLength(unreadNotifications.length || null);
}
                  
                  setNotification(groupedNotifications);

                  return combinedNotifications; 
              }

              return prevNotification; 
          });

          // console.log('notifyReact:', data?.payload);
      });

      return () => {
        console.log('Cleaning up subscription4');
        if(unsubscribe){
          console.log('Cleaning up subscription');
          unsubscribe(); 
        }
       
      };
  }
}, [connected, subscribe,isUpdate,customer]);


const fetchNotifications = async () => {
  setLoadingNotifications(true);
  setErrorNotifications(null);

  try {
   
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications/get`, {
      params:{
        type:"fats",
      },
      headers: {
        Authorization: localStorage.getItem('token'), 
      },
    });

   
    if (response.data.success && response.data.data) {
      setNotificationList(response.data.data);
      const groupedNotifications = groupNotificationsByDate(response.data.data);
      const unRead=response.data.data?.filter((un)=>!un?.readTypeFats);
  
      if(unRead?.length!==0){
        setUnreadLength(unRead?.length)
      }else{
        setUnreadLength(null)
      }
      setLoadingNotifications(false);
      setNotification(groupedNotifications);
    } else {
      setErrorNotifications('No notifications found.');
    }
  } catch (err) {
    setLoadingNotifications(false); 

    if (err.response && err.response.status === 404) {
      setErrorNotifications('No notifications found.'); 
    } else {
      setErrorNotifications('An error occurred while fetching notifications.');
    }
  } finally {
    setLoadingNotifications(false); 
  }
};


useEffect(() => {
  fetchNotifications();
}, [isUpdate,customer]);  



  useEffect(() => {
    const verifyCustomerId = async () => {
      if (localStorage.getItem("token")) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/user/verifyCustomerId`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          if (response.data.exists) {
            setIsAuthenticated(true);
            setLoading(false);
          } else {
            setError("Customer ID not found. Please log in.");
          }
        } catch (error) {
          setError("Failed to verify customer ID.");
          setIsAuthenticated(false);
          setLoading(false);
        }
      } else {
        setError("No customer ID found. Please log in.");
        setLoading(false);
        setIsAuthenticated(false);
      }
    };

    verifyCustomerId();
  }, []);

  useEffect(() => {
    

    const fetchData = async () => {
      try {
        // Define the URLs based on customerId
        const departmentUrl = `${process.env.REACT_APP_BASE_URL}/masters/department/get`;
        const designationUrl = `${process.env.REACT_APP_BASE_URL}/masters/designation/get`;
        const employmentTypeUrl = `${process.env.REACT_APP_BASE_URL}/masters/employmenttype/get`;
        const workLocationUrl = `${process.env.REACT_APP_BASE_URL}/masters/location/get`;

        // Make all API requests simultaneously
        const [
          departmentRes,
          designationRes,
          employmentTypeRes,
          workLocationRes,
        ] = await Promise.all([
          axios.get(departmentUrl, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(designationUrl, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(employmentTypeUrl, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
          axios.get(workLocationUrl, {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }),
        ]);

        // Store the responses in state

        const uniqueDepartments = departmentRes?.data?.departments.reduce(
          (acc, dept) => {
            // Normalize department name by trimming spaces
            const normalizedDepartment = dept.department.trim();

            // Check if department already exists in the accumulator
            const existingDept = acc.find(
              (d) => d.department === normalizedDepartment
            );

            if (existingDept) {
              // Update designations if not already present
              if (!existingDept.designations.includes(dept.designation)) {
                existingDept.designations.push(dept.designation);
              }
            } else {
              // Create a new entry with all keys
              acc.push({
                ...dept,
                department: normalizedDepartment,
                designations: [dept.designation],
              });
            }

            return acc;
          },
          []
        );

        // Remove the extra designations keys from the final output
        const result = uniqueDepartments.map((dept) => {
          const {
            _id,
            customerid,
            department,
            departmentattributes,
            __v,
            designations,
          } = dept;
          return {
            _id,
            customerid,
            department,
            departmentattributes,
            __v,
            designations,
          };
        });

        // setDepartment(result);
        setDepartment(
          departmentRes?.data?.departments?.map((dep) => ({
            ...dep,
            isTick: false,
          }))
        );

        setDesignation(
          designationRes?.data?.designations.map((des) => ({
            ...des,
            isTick: false,
          }))
          // departmentRes?.data?.departments?.map((dep) => ({
          //   ...dep,
          //   isTick: false,
          // }))
        );
        setEmployment(
          employmentTypeRes?.data?.employmenttypes.map((emp) => ({
            ...emp,
            isTick: false,
          }))
        );
        setWorkLocation(
          workLocationRes?.data?.locations.map((loc) => ({
            ...loc,
            isTick: false,
          }))
        );
        setLoadingMultipleFilter(false);
      } catch (error) {
        setErrorMultipleFilter(error);
      } finally {
        setLoadingMultipleFilter(false);
      }
    };

    fetchData();
  }, [customer]);





  if (loading) {
    return (
      <>
        <div className="Loader">
          <img src={Loader} alt="Loading" />
        </div>
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Animation />} />
          <Route path="/redirect-loader/:token" element={<RedirectPage />} />

          <Route element={<PublicRoute />}>
          <Route path="/login" element={<Login />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/forgotpassword" element={<Forgetpassword />} />
          </Route>
          

          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/reports-cards" element={<ReportsDetails />} />
            <Route path="/admin-account" element={<AdminAccount />} />
            <Route path="/live-tracker" element={<LiveTracker />} />
            <Route path="/emp-details" element={<EmployeeDetails />} />
            <Route path="/notifications" element={<Notifications />} />

          </Route>

          <Route path="*" element={
            <UnknownRoute />
            } />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
