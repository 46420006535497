import React, { useState } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import Heading from "../../components/reports/Heading/Heading";
import AttendanceTable from "../../components/reports/reportTable/AttendanceTable";
import CompleteLog from "../../components/reports/reportTable/CompleteLog";

const ReportsDetails = () => {
  const [tab, setTab] = useState(0);
  const [isFilter,setIsFilter]=useState(false);
  return (
    <Layout>
      <section className="reports" >
        <Heading tab={tab} setTab={setTab} isFilter={isFilter} setIsFilter={setIsFilter}/>
      </section>
      <div className="list_of_attendance" >
        <div className="list_of_data">
          {tab === 0 ? <AttendanceTable isFilter={isFilter} setIsFilter={setIsFilter}/>
           :
            <CompleteLog isFilter={isFilter} setIsFilter={setIsFilter} />}
        </div>
      </div>
    </Layout>
  );
};

export default ReportsDetails;
