import React from "react";
import logo from "../../../assets/images/Group.svg";
import userLogo from "../../../assets/images/userLogo.svg";
import account_icons from "../../../assets/images/account_icons.svg";
import notificatins_icons from "../../../assets/images/notificatins_icons.svg";
import { useNavigate, useLocation } from "react-router-dom";
import Confirm from "../../../model/confirm/Confirm";
import { useModel } from "../../../hooks/useModel/useModel";
import { useCustomer } from "../../../context/CustomerProvider.jsx";
import { useNotifications } from "../../../context/NotificationsProvider.jsx";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
const message = {
  title: "Sure you need to logout?",
  content: "You need to enter your password while login again.",
  leftBtn: "Logout",
  rightBtn: "Cancel",
  type: "Logout",
};
const Navbar = () => {
  const naviagte = useNavigate();
  const { pathname } = useLocation();

  const { open, handleClose, handleOpen } = useModel();
  const { customer } = useCustomer();
  const { unreadLength } = useNotifications();

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#09355C",
    },
  }));

  return (
    <div className="navbar">
      <div className="left_nav">
        <img src={logo} alt="logo" />
      </div>
      <div className="right_nav">
        <div
          className="menu_nav subMenu_nav"
          onClick={() => naviagte("/admin-account")}
        >
          <div className="one">
            <span>
              <b>
                {customer?.data?.businessname?.split(" ").slice(0, 2).join(" ")}
                {customer?.data?.businessname?.split(" ").length > 2 &&
                  "..."}{" "}
              </b>
            </span>
          </div>
          <div className="two">
          <BootstrapTooltip title="Profile">
          <img
              style={{
                border:
                  pathname === "/admin-account"
                    ? "2px solid green"
                    : "2px solid  #09355C",
              }}
              src={customer?.data?.businesslogo}
              alt="user"
              className="logoCompanyNavbar"
            />
          </BootstrapTooltip>
         
          </div>
        </div>
        <div
          className="menu_nav notification"
          onClick={() => {
            naviagte("/notifications");
          }}
        >
          <BootstrapTooltip title="Notifications">
            <img src={notificatins_icons} alt="notificatins" />
          </BootstrapTooltip>

          {unreadLength && (
            <div className="notification_number">{unreadLength}</div>
          )}
        </div>
        <div className="menu_nav ">
          <BootstrapTooltip title="Logout">
            <img src={account_icons} alt="account" onClick={handleOpen} />
          </BootstrapTooltip>
        </div>
      </div>
      <Confirm open={open} handleClose={handleClose} message={message} />
    </div>
  );
};

export default Navbar;
