import React, { useState, useEffect } from "react";
import moment from "moment";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { useEmployee } from "../../../context/EmpProvider";
import { useCustomer } from "../../../context/CustomerProvider";
import TableUi from "../../../skeleton/reports/TableUi";
import { useAttendanceTable } from "../../../hooks/useTime/attendanceTable";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Pagination from "@mui/material/Pagination";
import { TbArrowsDiagonal } from "react-icons/tb";
import ViewImage from "../../../model/ViewImage/ViewImage";
import { useModel } from "../../../hooks/useModel/useModel";

const AttendanceTable = ({ isFilter, setIsFilter }) => {
  const {
    attendanceData,
    setAttendanceData,
    setAttendanceFilter,
    setLogLength,
    reportLoading,
  } = useEmployee();

  const [paginationLoader, setPaginationLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLimit, setIsLimit] = useState(false);
  const [limit, setLimit] = useState(10);
  const [totalPage, setTotalPage] = useState("");
  const [page, setPage] = React.useState(1);
  const [error, setError] = useState(null);
  const [companyLogo, setComplanyLogo] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const { customer } = useCustomer();
  const { getTimeByCountry } = useAttendanceTable();
  const { handleClose, handleOpen, open } = useModel();

  useEffect(() => {
    setComplanyLogo(customer?.data?.businesslogo);
  }, [customer]);

  useEffect(() => {
    let isMounted = true;

    const fetchAttendanceData = async () => {
      try {
        setPaginationLoader(true);
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/liveTracker/getAttenDanceReports?page=${page}&limit=${limit}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (isMounted) {
          setAttendanceData(data?.reports || []);
          setPage(data?.currentPage);
          setTotalPage(data?.totalPages);
          setLogLength(data?.reports?.length || 0);
          setAttendanceFilter(data?.reports || []);
        }
      } catch (error) {
        if (isMounted) {
          setError(error.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          setPaginationLoader(false);
        }
      }
    };
    console.log("again call");
    fetchAttendanceData();

    return () => {
      isMounted = false;
    };
  }, [page, limit]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const formatDate = (dateString) => {
    return moment(dateString).format("DD-MM-YYYY");
  };
  const handleRefresh = () => {
    window.location.reload();
  };
  const handleLogDownload = async () => {
    try {
      const dataForSummeryApi = attendanceData;

      const doc3 = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
      });

      if (companyLogo) {
        const logoImage = new Image();
        logoImage.src = companyLogo;

        logoImage.onload = async () => {
          const originalWidth = logoImage.width;
          const originalHeight = logoImage.height;

          const maxWidth = 70;
          const maxHeight = 35;

          let logoWidth = originalWidth;
          let logoHeight = originalHeight;

          const aspectRatio = originalWidth / originalHeight;

          if (logoWidth > maxWidth) {
            logoWidth = maxWidth;
            logoHeight = maxWidth / aspectRatio;
          }

          if (logoHeight > maxHeight) {
            logoHeight = maxHeight;
            logoWidth = maxHeight * aspectRatio;
          }

          doc3.addImage(companyLogo, "JPEG", 10, 10, logoWidth, logoHeight);

          const tableColumn3 = [
            "S.No.",
            "Date",
            "Employee ID",
            "Employee Name",
            "Shift Timing",
            "In-Timing",
            "InSnippet",
            "Out-Timing",
            "Out-Snippet",
          ];

          const tableRows3 = dataForSummeryApi.map((member, index) => {
            return [
              index + 1,
              formatDate(member.date) || "--",
              member.employeeId || "--",
              member.employeeName || "--",
              member.shiftTiming || "--",

              customer?.data?.country && member?.firstInTime
                ? getTimeByCountry(
                    customer?.data?.country,
                    member?.firstInTime,
                    customer?.data?.state,
                    member?.shiftTiming
                  )?.newTimePost18Conditionally?.newTimeLate
                : "NA" || "--",

              "",

              customer.data.country &&
              member.lastOutTime &&
              member.lastOutTime !== "N/A"
                ? getTimeByCountry(
                    customer?.data?.country,
                    member?.lastOutTime,
                    customer?.data?.state,
                    member?.shiftTiming
                  )?.newTimePost18Conditionally?.newTimeLate
                : "NA" || "--",
              "",
            ];
          });

          const pageWidth = doc3.internal.pageSize.width;
          const totalTableWidth = 15 + 30 + 30 + 35 + 35 + 20 + 25 + 25 + 25;

          const leftMargin = (pageWidth - totalTableWidth) / 2;
          const title = "In-Out Time Attendance Summary";
          const textWidth = doc3.getTextWidth(title);
          const textX = (pageWidth - textWidth) / 2;

          doc3.text(title, textX, 40);
          doc3.setTextColor(46, 46, 46);

          autoTable(doc3, {
            head: [tableColumn3],
            body: tableRows3,
            startY: 50,
            margin: { top: 50, left: leftMargin, right: 10 },
            styles: { overflow: "linebreak", cellWidth: "wrap" },
            bodyStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
            headStyles: { cellPadding: { top: 7, bottom: 7, left: 2 } },
            columnStyles: {
              0: { cellWidth: 15 },
              1: { cellWidth: 30 },
              2: { cellWidth: 35 },
              3: { cellWidth: 35 },
              4: { cellWidth: 25 },
              5: { cellWidth: 20 },
              6: { cellWidth: 30 },
              7: { cellWidth: 23 },
              8: { cellWidth: 30 },
            },
            didDrawCell: (data) => {
              if (
                data.section === "body" &&
                (data.column.index === 6 || data.column.index === 8)
              ) {
                console.log("row check", data.row.index);

                const rowIndex = data.row.index;
                if (rowIndex >= 0 && rowIndex < dataForSummeryApi.length) {
                  const member = dataForSummeryApi[rowIndex];
                  const imageBase64 =
                    data.column.index === 6
                      ? member.inTimeSnippet
                      : member.outTimeSnippet;

                  if (imageBase64) {
                    try {
                      doc3.addImage(
                        `data:image/jpeg;base64,${imageBase64}`,
                        "JPEG",
                        data.cell.x + 2,
                        data.cell.y + 2,
                        15,
                        15
                      );
                    } catch (error) {
                      console.log("Error adding image:", error);
                    }
                  }
                }
              }
            },
            horizontalPageBreakRepeat: true,
          });

          doc3.save(`attendance.pdf`);
        };
        logoImage.onerror = () => {
          console.error(
            "Failed to load the logo image from the base64 string."
          );
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const checkIsLate = (inTime, shift, graceTime) => {
    console.log(inTime, shift, graceTime);

    const [a, b] = shift.split("-")[0].split(":");
    const officeStartTime = new Date();
    officeStartTime.setHours(Number(a), Number(b), 0, 0);

    const gracePeriod = Number(graceTime) * 60 * 1000;
    const gracePeriodEnd = new Date(officeStartTime.getTime() + gracePeriod);

    let userInTime;
    if (isNaN(Date.parse(inTime))) {
      const [time, meridian] = inTime.split(" ");
      const [hours, minutes, seconds] = time.split(":").map(Number);
      userInTime = new Date();
      userInTime.setHours(
        meridian.toLowerCase() === "pm" && hours !== 12 ? hours + 12 : hours,
        minutes,
        seconds || 0,
        0
      );
    } else {
      userInTime = new Date(inTime);
    }

    if (userInTime > gracePeriodEnd) {
      const lateTime = userInTime - gracePeriodEnd;
      const hoursLate = Math.floor(lateTime / (1000 * 60 * 60));
      const minutesLate = Math.floor(
        (lateTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLate = Math.floor((lateTime % (1000 * 60)) / 1000);

      return {
        message: `${hoursLate}hr ${minutesLate}min ${secondsLate}sec Late`,
        success: true,
      };
    } else {
      return { message: "On Time", success: false };
    }
  };
  const handleVisibleLimitBox = () => {
    setIsLimit((prev) => !prev);
  };

  const tableHeading = [
    { id: 1, name: "S.NO" },
    { id: 2, name: "Date" },
    { id: 3, name: "Employee ID" },
    { id: 4, name: "Employee Name" },
    { id: 5, name: "Shift Timing" },
    { id: 6, name: "In-Timing" },
    { id: 7, name: "In-Timing Snippet" },
    { id: 8, name: "Out-Timing" },
    { id: 9, name: "Out-Timing Snippet" },
  ];
  if (reportLoading) {
    return <TableUi />;
  }
  if (loading) {
    return <TableUi />;
  }
  if (error) {
    return (
      <div className="list_of_dataLoading">
        <h1>No Attendance Records Found</h1>
        <button className="Refresh" onClick={handleRefresh}>
          Refresh
        </button>
      </div>
    );
  }
  const handleImageZoom = (img, name) => {
    if(!img){
      return;
    }
    setSelectedImage({
      name: name,
      pic: img,
    });
    handleOpen();
  };
  return (
    <div className="attendanceTable">
      <div className="list">
        <table>
          <thead>
            <tr className="tableAttendanceHeading">
              {tableHeading.map((h) => (
                <th key={h?.id}>{h?.name}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {attendanceData.length > 0 ? (
              attendanceData.map((data, index) => {
                let i;
                if (page === 1) {
                  i = index + 1;
                } else {
                  i = index + 1 + limit * page;
                }

                return (
                  <tr key={index} className="tableDataName">
                    <td>{i}</td>
                    <td>{formatDate(data.date)}</td>
                    <td>{data.employeeId}</td>
                    <td>{data.employeeName}</td>
                    <td>{data?.shiftTiming ? data.shiftTiming : "N/A"}</td>

                    {customer.data.country &&
                      (checkIsLate(
                        getTimeByCountry(
                          customer?.data.country,
                          data.firstInTime,
                          customer?.data.state,
                          data?.shiftTiming
                        )?.newTime,
                        data?.shiftTiming,
                        data?.graceTime
                      )?.success ? (
                        <td style={{ color: "red" }}>
                          {
                            checkIsLate(
                              getTimeByCountry(
                                customer?.data.country,
                                data.firstInTime,
                                customer?.data.state,
                                data?.shiftTiming
                              )?.newTime,
                              data?.shiftTiming,
                              data?.graceTime
                            )?.message
                          }
                        </td>
                      ) : (
                        <td style={{ color: "green" }}>
                          {
                            checkIsLate(
                              getTimeByCountry(
                                customer?.data.country,
                                data.firstInTime,
                                customer?.data.state,
                                data?.shiftTiming
                              )?.newTime,
                              data?.shiftTiming,
                              data?.graceTime
                            )?.message
                          }
                        </td>
                      ))}

                    <td>
                      <div
                        className="attendanceTablePic"
                        onClick={() =>
                          handleImageZoom(data.inTimeSnippet, data.employeeName)
                        }
                      >
                        {data.inTimeSnippet ? (
                          <img
                            style={{ height: "42px", width: "40px" }}
                            src={`data:image/png;base64,${data.inTimeSnippet}`}
                            alt="In Time Snippet"
                          />
                        ) : (
                          "No snippet available"
                        )}
                        {data.inTimeSnippet && (
                          <div className="overlayAttendanceTablePic">
                            <TbArrowsDiagonal color="white" fontSize={20} />
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      {customer.data.country &&
                      data.lastOutTime &&
                      data.lastOutTime !== "N/A" &&
                      getTimeByCountry(
                        customer?.data.country,
                        data?.lastOutTime,
                        customer?.data.state,
                        data?.shiftTiming
                      )?.newTimePost18Conditionally?.type ? (
                        <td>
                          {
                            getTimeByCountry(
                              customer?.data.country,
                              data?.lastOutTime,
                              customer?.data.state,
                              data?.shiftTiming
                            )?.newTimePost18Conditionally?.newTime
                          }
                        </td>
                      ) : (
                        <td style={{ color: "red" }}>
                          {
                            getTimeByCountry(
                              customer?.data.country,
                              data?.lastOutTime,
                              customer?.data.state,
                              data?.shiftTiming
                            )?.newTimePost18Conditionally?.lateMessage
                          }
                        </td>
                      )}
                      <td>
                        {customer.data.country &&
                        data.lastOutTime &&
                        data.lastOutTime !== "N/A"
                          ? getTimeByCountry(
                              customer?.data.country,
                              data?.lastOutTime,
                              customer?.data.state,
                              data?.shiftTiming
                            )?.newTimePost18Conditionally?.type
                          : "NA"}
                      </td>
                    </td>

                    <td>
                      <div
                        className="attendanceTablePic"
                        onClick={() =>
                          handleImageZoom(
                            data.outTimeSnippet,
                            data.employeeName
                          )
                        }
                      >
                        {data.outTimeSnippet ? (
                          <img
                            style={{ height: "42px", width: "40px" }}
                            src={`data:image/png;base64,${data.outTimeSnippet}`}
                            alt="Out Time Snippet"
                          />
                        ) : (
                          "No snippet available"
                        )}

                         {data.outTimeSnippet && (
                          <div className="overlayAttendanceTablePic">
                            <TbArrowsDiagonal color="white" fontSize={20} />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: "center" }}>
                  <h1> No employee data found</h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="button_download">
        {!isFilter && (
          <div className="pagination">
            <div className="leftPagination">
              <div className="boxOfPagination" onClick={handleVisibleLimitBox}>
                <span>{limit}</span>
                <span>
                  {!isLimit ? (
                    <IoIosArrowDown fontSize={20} />
                  ) : (
                    <IoIosArrowUp fontSize={20} />
                  )}
                </span>
              </div>
              {isLimit && (
                <div className="dropDownPagination">
                  {[10, 30, 50, 100, 150]?.map((s, i) => (
                    <div
                      className={`skipPage ${
                        i !== 0 && i !== 4 && "skipPageMargin"
                      }`}
                      onClick={() => {
                        setLimit(s);
                        setIsLimit(!isLimit);
                      }}
                    >
                      <span key={i}>{s}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="rightPagination">
              <div className="boxOfPaginationRight">
                <div style={{ position: "relative" }}>
                  <Pagination
                    count={totalPage}
                    disabled={paginationLoader}
                    sx={{
                      "& .Mui-selected": {
                        backgroundColor: "#0A3B66",
                        color: "#FFFFFF",
                      },
                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#D3D3D3",
                        color: "#FFFFFF",
                      },
                    }}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <button onClick={handleLogDownload} className="downloadBtn">
          <span>Download</span>
        </button>
      </div>
      <ViewImage open={open} handleClose={handleClose} pic={selectedImage} />
    </div>
  );
};

export default AttendanceTable;
