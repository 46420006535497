import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout/AdminLayout/Layout";
import LiveTrackerSkeleton from "../../skeleton/liveTracker/LiveTrackerSkeleton";
import { useCustomer } from "../../context/CustomerProvider";
import TimeFormatter from "../../utils/gloablDateTimeFunction";
import { useNotifications } from "../../context/NotificationsProvider";
import search_icons from "../../assets/images/searchIcons.svg";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useAttendanceTable } from "../../hooks/useTime/attendanceTable";
import ViewImage from "../../model/ViewImage/ViewImage";
import { useModel } from "../../hooks/useModel/useModel";
import okulrImage from "../../assets/images/Okulr_Logo.png"
import { TbArrowsDiagonal } from "react-icons/tb";
const LiveTracker = () => {
  const [liveTracker, setLiveTracker] = useState([]);
  const [filterLiveTracker, setFilterLiveTrackerLiveTracker] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const lastDataRef = useRef([]); // Store the last received data
  const { customer } = useCustomer();
  const [selectedImage,setSelectedImage]=useState(null);
  const {
    setNotification,
    setUnreadLength,
    setLoading: setLoadingNotifications,
    setError: setErrorNotifications,
    connected,
    subscribe,
  } = useNotifications();

  // Function to perform long polling
  // const pollLiveTracker = async () => {
  //   try {
  //     // Get the customerId from localStorage

  //     // Generate the current date in ISO format
  //     const currentDate = new Date();
  //     const startOfDay = new Date(
  //       currentDate.setHours(0, 0, 0, 0)
  //     ).toISOString(); // Start of the day

  //     // API call to fetch records for the entire day
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_BASE_URL}/liveTracker/fetch`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: localStorage.getItem("token"),
  //         },
  //       }
  //     );

  //     const data = response.data.data;

  //     // Check if new data is different from last data
  //     const isNewData =
  //       JSON.stringify(data) !== JSON.stringify(lastDataRef.current);
  //     if (isNewData) {
  //       // Update the state if new data is found
  //       lastDataRef.current = data;

  //       // Create a set to keep track of employee IDs we've seen
  //       const seenEmployeeIds = new Set();
  //       const firstRecords = data.filter((record) => {
  //         if (!seenEmployeeIds.has(record.employeeId)) {
  //           seenEmployeeIds.add(record.employeeId);
  //           return true; // Keep this record
  //         }
  //         return false; // Skip this record
  //       });

  //       setLiveTracker(firstRecords);
  //       setError(null);
  //     }
  //     setLoading(false); // Stop loading after data is fetched
  //   } catch (err) {
  //     setError(err.message);
  //     setLoading(false); // Stop loading on error
  //   }
  // };

  // useEffect(() => {
  //   pollLiveTracker();
  //   const pollingInterval = setInterval(() => {
  //     pollLiveTracker();
  //   }, 8000);
  //   return () => clearInterval(pollingInterval);
  // }, []);
 
  const { getTimeByCountry } = useAttendanceTable();
const {handleClose,handleOpen,open}=useModel();
  const pollLiveTracker = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/liveTracker/fetch`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      const data = response.data.data;
      if (response.data.success) {
        const sortedData = data.sort(
          (a, b) => new Date(a?.inTime) - new Date(b?.inTime)
        );
        setLiveTracker(sortedData);
        setFilterLiveTrackerLiveTracker(sortedData);
        setError(null);
      }
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    pollLiveTracker();
  }, []);

  useEffect(() => {
    if (connected) {
      const unsubscribe = subscribe("notifyReactLiveTracker", (data) => {
        let liveTrackerData = data?.payload || null;
        console.log(liveTrackerData);

        setLiveTracker((prevLiveTracker) => {
          const existingNotifications = new Set(
            prevLiveTracker?.map((live) => live.employeeId)
          );

          if (
            liveTrackerData &&
            !existingNotifications.has(liveTrackerData.employeeId)
          ) {
            const combinedNotifications = [...prevLiveTracker, liveTrackerData];

            const sortedNotifications = combinedNotifications.sort(
              (a, b) => new Date(a?.inTime) - new Date(b?.inTime)
            );
            return sortedNotifications;
          }

          return prevLiveTracker;
        });

        setFilterLiveTrackerLiveTracker((prevLiveTracker) => {
          const existingNotifications = new Set(
            prevLiveTracker?.map((live) => live.employeeId)
          );

          if (
            liveTrackerData &&
            !existingNotifications.has(liveTrackerData.employeeId)
          ) {
            const combinedNotifications = [...prevLiveTracker, liveTrackerData];

            const sortedNotifications = combinedNotifications.sort(
              (a, b) => new Date(a?.inTime) - new Date(b?.inTime)
            );
            return sortedNotifications;
          }

          return prevLiveTracker;
        });
      });

      return () => {
        if (unsubscribe) {
          console.log("Cleaning up liveTracker");
          unsubscribe();
        }
      };
    }
  }, [connected, subscribe]);



  const searchEmployees = (data) => {
    const attendanceInfo = filterLiveTracker.filter((employee) => {
      return (
        employee.employeeId.toLowerCase().includes(data.toLowerCase()) ||
        employee.employeeName.toLowerCase().includes(data.toLowerCase())
      );
    });

    setLiveTracker(attendanceInfo);
  };
  const checkIsLate = (inTime, shift, graceTime) => {
    console.log(inTime, shift, graceTime);

    const [a, b] = shift.split("-")[0].split(":");
    const officeStartTime = new Date();
    officeStartTime.setHours(Number(a), Number(b), 0, 0);

    const gracePeriod = Number(graceTime) * 60 * 1000;
    const gracePeriodEnd = new Date(officeStartTime.getTime() + gracePeriod);

    let userInTime;
    if (isNaN(Date.parse(inTime))) {
      const [time, meridian] = inTime.split(" ");
      const [hours, minutes, seconds] = time.split(":").map(Number);
      userInTime = new Date();
      userInTime.setHours(
        meridian.toLowerCase() === "pm" && hours !== 12 ? hours + 12 : hours,
        minutes,
        seconds || 0,
        0
      );
    } else {
      userInTime = new Date(inTime);
    }

    if (userInTime > gracePeriodEnd) {
      const lateTime = userInTime - gracePeriodEnd;
      const hoursLate = Math.floor(lateTime / (1000 * 60 * 60));
      const minutesLate = Math.floor(
        (lateTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const secondsLate = Math.floor((lateTime % (1000 * 60)) / 1000);

      return {
        message: `${hoursLate}hr ${minutesLate}min ${secondsLate}sec Late`,
        success: true,
      };
    } else {
      return { message: "On Time", success: false };
    }
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#09355C",
    },
  }));
const handleImageZoom=(img,name)=>{
  setSelectedImage({
    name:name,
    pic:img
  });
  handleOpen();
}
  return (
    <Layout>
      <section className="liveTracker">
        <div
          className="one"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "70px",
          }}
        >
          <h3>Live Attendance</h3>
          <div className="searchBox">
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => searchEmployees(e.target.value)}
              style={{ "--search-icon": `url(${search_icons})` }}
            />
          </div>
        </div>
        <div className="commonBorderForAll">
          {error ? (
            <div className="logDetailsLoader">
              <h1>No Attendance Found</h1>
            </div>
          ) : (
            <>
              <div className="heading ">
                <h1>Today’s Attendance</h1>
              </div>
              {loading && <LiveTrackerSkeleton />}
              <div className="listOfAttendanceEmployee">
                {liveTracker?.map((employee, index) => {
                  return (
                    <div className="card" key={index}>
                      <div
                      onClick={()=>handleImageZoom(employee.inTimeSnippet,employee.employeeName)}
                        className="upper"
                        id="upperImageHover"
                        style={{
                          border: !checkIsLate(
                            getTimeByCountry(
                              customer?.data.country,
                              employee.inTime,
                              customer?.data.state,
                              employee?.shift
                            )?.newTime,
                            employee?.shift,
                            employee?.graceTime
                          )?.success
                            ? "2px solid greenyellow"
                            : "2px solid red",
                            cursor:"pointer"
                        }}
                      > 
                        {employee.inTimeSnippet ? (
                          <img
                            src={`data:image/png;base64,${employee.inTimeSnippet}`}
                            alt="employeePic"
                            id="imgLiveTracker"
                          />
                        ) : (
                          "No snippet available"
                        )}

       <div className="overlay">
         <TbArrowsDiagonal color="white" fontSize={35}/>
        </div>
                      </div>
                      <div className="lower">
                        <div className="details">
                          <div className="name_heading">Name</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <BootstrapTooltip title={employee.employeeName}>
                              <b>
                                {employee.employeeName?.length > 10
                                  ? `${employee.employeeName.slice(0, 10)}...`
                                  : employee.employeeName}
                              </b>
                            </BootstrapTooltip>
                          </div>
                        </div>

                        <div className="details">
                          <div className="name_heading">Emp. ID</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <BootstrapTooltip title={employee.employeeId}>
                              <b>
                                {employee.employeeId?.length > 8
                                  ? `${employee.employeeId.slice(0, 9)}...`
                                  : employee.employeeId}
                              </b>
                            </BootstrapTooltip>
                          </div>
                        </div>

                        <div className="details">
                          <div className="name_heading">In Time</div>
                          <div className="dot">:</div>
                          <div className="name">
                            <b>
                              {customer.data.country && customer.data.state && (
                                <TimeFormatter
                                  country={customer.data.country}
                                  state={customer.data.state}
                                  time={employee?.inTime}
                                />
                              )}
                            </b>
                          </div>
                        </div>

                        <div className="timeCheck">
                          
                          <p>
                            {checkIsLate(
                            getTimeByCountry(
                              customer?.data.country,
                              employee.inTime,
                              customer?.data.state,
                              employee?.shift
                            )?.newTime,
                            employee?.shift,
                            employee?.graceTime
                          )?.success && (
                              <b>
                                {
                                  checkIsLate(
                                    getTimeByCountry(
                                      customer?.data.country,
                                      employee.inTime,
                                      customer?.data.state,
                                      employee?.shift
                                    )?.newTime,
                                    employee?.shift,
                                    employee?.graceTime
                                  )?.message
                                }
                              </b>
                            )}
                          </p>


                          <p>
                            {!checkIsLate(
                            getTimeByCountry(
                              customer?.data.country,
                              employee.inTime,
                              customer?.data.state,
                              employee?.shift
                            )?.newTime,
                            employee?.shift,
                            employee?.graceTime
                          )?.success && (
                              <b>
                                {
                                  checkIsLate(
                                    getTimeByCountry(
                                      customer?.data.country,
                                      employee.inTime,
                                      customer?.data.state,
                                      employee?.shift
                                    )?.newTime,
                                    employee?.shift,
                                    employee?.graceTime
                                  )?.message
                                }
                              </b>
                            )}
                          </p>


                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
      <ViewImage open={open} handleClose={handleClose} pic={selectedImage}/>
    </Layout>
  );
};

export default LiveTracker;
