
import React,{useState,useEffect} from 'react'
import Layout from '../../components/Layout/AdminLayout/Layout'
import { useNotifications } from '../../context/NotificationsProvider'
import moment from "moment";
import axios from "axios";
import NotificationsSkelton from '../../skeleton/notifications/NotificationsSkelton';

const Notifications = () => {
    const {
      notification,
      error,
      setError,
      loading,  
      setUnreadLength,
      setNotificationList,
      setNotification,
    }=useNotifications();


    const groupNotificationsByDate = (notifications) => {
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');
      
      // First, sort notifications by date in descending order
      const sortedNotifications = notifications.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
      
      // Group notifications by "Today", "Yesterday", and other dates
      const grouped = sortedNotifications.reduce((acc, notification) => {
        const notificationDate = moment(notification.createdAt);
        
        let dateLabel;
        if (notificationDate.isSame(today, 'day')) {
          dateLabel = "Today";
        } else if (notificationDate.isSame(yesterday, 'day')) {
          dateLabel = "Yesterday";
        } else {
          dateLabel = notificationDate.format("DD/MM/YYYY");
        }
        
        if (!acc[dateLabel]) {
          acc[dateLabel] = [];
        }
        acc[dateLabel].push(notification);
        
        return acc;
      }, {});
      
      // Sort the notifications within each date group (e.g., "Today", "Yesterday") in descending order by `createdAt`
      Object.keys(grouped).forEach((key) => {
        grouped[key].sort((a, b) => moment(b.createdAt) - moment(a.createdAt));
      });
    
      return grouped;
    };

    const handleOperation=async ()=>{
      try{
        setError(null);
      const response=await axios.get(`${process.env.REACT_APP_BASE_URL}/notifications/read-notify`, {
                params:{
                  type:"fats",
                  strictType:"fats",

                },
                headers: {
                  Authorization: localStorage.getItem('token'), 
                },
              })
             
              if (response.data.success && response.data.data) {
      setUnreadLength(null);
      setNotificationList(response.data.data);

      const groupedNotifications = groupNotificationsByDate(response.data.data);  
      setNotification(groupedNotifications);
              }
      }catch(e){
      // console.log(e);
      setError('No notifications found.'); 

      }
      };
useEffect(()=>{
  handleOperation();
},[]);
useEffect(()=>{
if(Object.keys(notification).length!==0){
  setError(null)
}
},[notification]);
  return (
    <Layout>
        <div className="commonBorderForAll"
        style={{ marginTop: "40px", height: "90%" }}
        
        >

<div className="Main_Dashboard_Background">
          <div className="Dashboard_Section">
            {
             error ? <div
              style={{display:"flex",justifyContent:"center",alignItems:"center",height:"80vh",width:"100%"}}
              
              >

                  <h1>{error}</h1>
              </div>:

<div className="Account_Details">
<div
  className="Admin_Account"
  style={{ display: "flex", justifyContent: "space-between" }}
>  
  <h1>Notifications</h1>
 
</div>
{
  !loading ?

<div className="Admin_Details">

 <>
      {Object.keys(notification).map((dateLabel) => (
        <div className="Notifications_parent" key={dateLabel}>
          <div className="heading_notifications">
            {dateLabel}
          </div>
          <div className="message_notifications">
            {notification[dateLabel].map((notification) => (
              <div className="message_child_notifications" key={notification._id}>
                <p>{notification?.message}</p>
                <div className="date_notificat">
                  <span>{moment(notification.createdAt).format("MMM DD, YYYY [at] hh:mm A")}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
</div>


:
<NotificationsSkelton/>

}


</div>
            }
           
          </div>
        </div>
        </div>
    </Layout>
  )
}

export default Notifications